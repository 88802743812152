import React from "react";
import { Location, globalHistory } from "@reach/router";
import { QueryParamProvider } from "use-query-params";
import { ReachAdapter } from 'use-query-params/adapters/reach';

export default ({ children }) => (
    <Location>
        {({ location }) => (
            <QueryParamProvider location={location} adapter={ReachAdapter} reachHistory={globalHistory}>
                {children}
            </QueryParamProvider>
        )}
    </Location>
);